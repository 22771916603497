import styled, { css } from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Colors } from '../../../../../theme/palette';

export const OrdersViewPartContainer = styled(Flex)`
    flex: 1;
    flex-direction: column;
    gap: 20px;
    height: 100%;
`;

export const OrderViewHeaderContainer = styled(Flex)`
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 25px;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
`;

export const OrderViewHeaderItem = styled(Flex)`
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
`;
export const OrderViewHeaderItemCircle = styled(Flex)<{color?: string, active?: number}>`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    border: 2px solid ${props => props?.color || 'transparent'};
    cursor: pointer;
    
    ${props => props.active && css`
        background: ${props?.color || 'transparent'};
    `}
`;


export const OrderViewHeaderItemText = styled(Flex)`
    font: normal 400 18px/24px 'Readex Pro';
`;


export const OrderViewContentContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid ${Colors.gradient.yellowDark};
    padding: 20px;
`;




export const OrderViewListContainer = styled(Flex)`
    flex: 2;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    max-height: 900px;
    overflow-y: auto;
    padding-right: 10px;
    
    @media (max-width: 1921px) {
      max-height: 520px;
    }

    @media (max-width: 1450px) {
        max-height: 350px;
    }

    @media (max-width: 1366px) {
        max-height: 250px;
    }
`;
