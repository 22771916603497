import React, { useCallback, useContext, useMemo } from 'react';
import {
    OrderViewHeaderContainer,
    OrderViewHeaderItem,
    OrderViewHeaderItemCircle,
    OrderViewHeaderItemText
} from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import { Colors } from '../../../../../theme/palette';
import OrderDriverViewContext from './context/context';

const OrderViewHeader = () => {

    const {filter, setFilter, clearFilter} = useContext(OrderDriverViewContext);
    const handleFilter = useCallback((field: string)=>{
        if(filter?.[field]) clearFilter(field);
        else setFilter({field, data: true});
    } ,[setFilter, clearFilter, filter]);

    const [isActive, isBusy, isPause] = useMemo(()=> [
        +filter.free,
        +filter.busy,
        +filter.pause
    ], [filter]);

    const handleFilterActive = useCallback(()=> handleFilter('free') ,[handleFilter]);
    const handleFilterBusy = useCallback(()=> handleFilter('busy') ,[handleFilter]);
    const handleFilterPause = useCallback(()=> handleFilter('pause') ,[handleFilter]);
    
    return (
        <OrderViewHeaderContainer>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.green70} onClick={handleFilterActive} active={isActive}/>
                <Translation use={Translate.orderViewDriverFilterFreeText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.red} onClick={handleFilterBusy} active={isBusy}/>
                <Translation use={Translate.orderViewFilterBusyText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
            <OrderViewHeaderItem>
                <OrderViewHeaderItemCircle color={Colors.gradient.yellowDark} onClick={handleFilterPause} active={isPause}/>
                <Translation use={Translate.orderViewFilterBreakText} as={OrderViewHeaderItemText} />
            </OrderViewHeaderItem>
        </OrderViewHeaderContainer>
    );
};

export default OrderViewHeader;