import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { APP_VERSION } from '../../config';
import { SETTINGS_KEYS } from './d';
import { CONTEXT_TYPES } from '../../context/d';

// Define a type for the slice state
interface AppState {
    version: string
    settings?: any,
    drivers?: any,
    vehicles?: any,
    user?: any,
    token?: string
    refreshToken?: string
    open: boolean
    shiftDrivers?: any
}

// Define the initial state using that type
const initialState: AppState = {
    version: APP_VERSION || '',
    open: false,
    token: localStorage.getItem(CONTEXT_TYPES.ACCESS_TOKEN) as string || undefined,
    refreshToken: localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN) as string || undefined
};

export const appSlice = createSlice({
    name: 'app',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setVersion: (state, action: PayloadAction<string>) => {
            state.version = action.payload;
        },
        setSettings: (state, action: PayloadAction<any>) => {
            state.settings = {
                ...(state?.settings || {}),
                ...action.payload
            };
        },
        setDrivers: (state, action: PayloadAction<any>) => {
            state.drivers = [...(action.payload || [])];
        },
        setVehicles: (state, action: PayloadAction<any>) => {
            state.vehicles = [...(action.payload || [])];
        },
        setAuthData: (state, action: PayloadAction<any>) => {
            const {account, token, refreshToken} = action.payload;
            state.user = {...account};
            state.token = token;
            state.refreshToken = refreshToken;
            if (token) {
                localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, token);
            }
            if (refreshToken) {
                localStorage.setItem(CONTEXT_TYPES.REFRESH_TOKEN, refreshToken);
            }
        },
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload;
            if (action.payload) {
                localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, action.payload);
            } else {
                localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            }
        },
        setRefreshToken: (state, action: PayloadAction<any>) => {
            state.refreshToken = action.payload;
            if (action.payload) {
                localStorage.setItem(CONTEXT_TYPES.REFRESH_TOKEN, action.payload);
            } else {
                localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
            }
        },
        clearUserData: (state) => {
            state.refreshToken = undefined;
            state.token = undefined;
            state.user = undefined;
            localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
        },
        setShiftDrivers:  (state, action: PayloadAction<any>) => {
            state.shiftDrivers = action.payload;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        }
    }
});

export const { setVersion, setSettings, setVehicles, setDrivers, setOpen, setUser, setShiftDrivers, setToken, setRefreshToken, setAuthData, clearUserData } = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectVersion = (state: RootState) => state.app.version;
export const selectDrivers = (state: RootState) => state.app.drivers || [];
export const selectVehicles = (state: RootState) => state.app?.vehicles || [];
export const selectSettings = (state: RootState) => state.app.settings || {};
export const selectSettingsVehicleStartAmount = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_START_AMOUNT]?.value || '';
export const selectSettingsShifts = (state: RootState) => state.app.settings?.[SETTINGS_KEYS.SHIFT_SCHEDULING]?.valueJson || {};
export const selectGetVehicle = (id: number) => (state: RootState) => (state.app?.vehicles || []).find((x: any)=> x.id === id) || {};
export const selectGetDriver = (id: number) => (state: RootState) => (state.app?.drivers || []).find((x: any)=> x.id === id) || {};
export const selectAppStore = (state: RootState) => state.app;
export const selectToken = (state: RootState) => state.app.token;
export const selectUser = (state: RootState) => state.app.user;
export const selectIsAuthorized = (state: RootState) => !!state.app.token;
export const selectShiftDrivers = (state: RootState) => state.app.shiftDrivers;
export default appSlice.reducer;