import React, {useCallback, useMemo} from 'react';
import ButtonIcon from 'components/button/ButtonIcon';
import {Menu} from '@mui/material';
import {
    AppBarUserAvatar,
    AvatarMenuContainer,
    AvatarMenuImage,
    AvatarMenuRow, MenuLogoutButton,
    MenuUserInfo,
    MenuUserInfoOneRow,
    MenuUserInfoUserName
} from 'components/appbar/appbarRight/style';
import {Colors} from 'theme/palette';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserData, selectAppStore } from '../../../store/app/appSlice';


const UserAvatar = () => {
    const {user} = useSelector(selectAppStore);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);
    const handleClose = useCallback(() =>  {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const userFirstLetter = useMemo(() => (user as any)?.nickname?.charAt(0)?.toUpperCase(), [user]);

    const _userName = useMemo(()=> `${(user as any)?.nickname?.charAt(0)?.toUpperCase()}${(user as any)?.nickname?.substring(1,(user as any)?.nickname?.length)}` ,[user]);

    const handleLogout = useCallback(()=> {
        dispatch(clearUserData());
        handleClose();
    },[handleClose]);


    return (
        <>
            <ButtonIcon
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}

            >
                <AppBarUserAvatar>{userFirstLetter}</AppBarUserAvatar>
            </ButtonIcon>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        padding: 0
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                        elevation: 0,
                        sx: {
                            minWidth: 200,
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            border: `1px solid ${Colors.gradient.yellowLight}`,
                            borderRadius: '8px'
                        }
                }}
            >
                <AvatarMenuContainer>
                    <AvatarMenuRow>
                        <AvatarMenuImage>{userFirstLetter}</AvatarMenuImage>
                        <MenuUserInfo>
                            <MenuUserInfoOneRow>Dobrodošao,</MenuUserInfoOneRow>
                            <MenuUserInfoUserName>{_userName || ''} !</MenuUserInfoUserName>
                        </MenuUserInfo>
                    </AvatarMenuRow>
                    <AvatarMenuRow>
                        <MenuLogoutButton onClick={handleLogout}>Odjavi se</MenuLogoutButton>
                    </AvatarMenuRow>
                </AvatarMenuContainer>
            </Menu>
        </>
    );
};


export default UserAvatar;
