import React, { useState } from 'react';
import { OrdersViewDriverPartContainer, OrderViewDriverMapContainer } from './style';
import OrderViewHeader from './OrderViewHeader';
import OrderDriversViewList from './OrderDriversViewList';
import OrderDriverViewContextContainer from './context';


const OrdersDriverPart = () => {
    const [showMap, setShowMap] = useState<boolean>(false);
    console.log(setShowMap);
    
    return (
        <OrderDriverViewContextContainer filter={{
            active: true
        }}>
            <OrdersViewDriverPartContainer>
                <OrderViewHeader />
                <OrderDriversViewList/>
                {showMap && (<OrderViewDriverMapContainer>

                </OrderViewDriverMapContainer>)}
            </OrdersViewDriverPartContainer>
        </OrderDriverViewContextContainer>
    );
};

export default OrdersDriverPart;