import {Navigate, Outlet} from 'react-router-dom';
import {GuestLayoutContainer, GuestLayoutContent, GuestLayoutHeader, GuestLayoutImage} from 'layouts/style';
import pattern from 'assets/images/pattern.png';
import {isMobile} from 'react-device-detect';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '../store/app/appSlice';

const GuestLayout =() =>{
    const token = useSelector(selectToken);

    if(isMobile) {
        return <Navigate to="/only-desktop" replace />;
    }

    if (token) {
        return <Navigate to="/" replace />;
    }

    return (
        <GuestLayoutContainer id="guestLayout">
            <GuestLayoutImage src={pattern}/>
            <GuestLayoutHeader>
                Dispičer app
            </GuestLayoutHeader>
            <GuestLayoutContent>
                <Outlet />
            </GuestLayoutContent>
        </GuestLayoutContainer>
    );
};



export default GuestLayout;
