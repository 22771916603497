import React from 'react';
import {LoginBox, LoginContainer, LoginForm, LoginImage} from 'views/Login/style';
import logo from 'assets/images/logo-login.png';
import {useFormik} from 'formik';
import {Input, InputPassword} from 'components/input';
import {Grid} from '@mui/material';
import Button from '../../components/button';
import useLoginMutation from 'core/api/hooks/mutations/useLoginMutation';
import {initialValueLogin, LoginValues, validationLoginSchema} from './d';
import { setAuthData } from '../../store/app/appSlice';
import { useDispatch } from 'react-redux';


const Login = () => {
    const dispatch = useDispatch();
    const { mutate: loadingMutation } = useLoginMutation();
    const onSubmit = (values: LoginValues, {setFieldError}: any) => {
        if (!values) return;
        loadingMutation(
            { ...values },
            {
                onSuccess: async (_data: any) => {
                  const {data} = _data;
                    dispatch(setAuthData(data));
                },
                onError: async (error: any) => {
                    if(error?.response?.data) {
                        setFieldError('password', error?.response?.data?.key);
                    }
                }
            }
         );
    };


    const formik = useFormik({
        initialValues: {
            ...initialValueLogin
        },
        validationSchema: validationLoginSchema,
        onSubmit,
    });


    return (
        <LoginContainer>
            <LoginBox>
                <LoginImage src={logo} alt={'Ding Dong Dostava'}/>
                <LoginForm>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Input
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik?.errors?.email}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <InputPassword
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        variant="outlined"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik?.errors?.password}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button type={'submit'} variant="contained" color="primary" fullWidth={true}>Prijavi se</Button>
                                </Grid>
                            </Grid>
                        </form>
                </LoginForm>
            </LoginBox>
        </LoginContainer>
    );
};


export default Login;
