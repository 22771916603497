import {
    DefineOrderForm,
    DefineOrderIsScheduled,
    DefineOrderRow,
    DefineOrderSectionLabel,
    DefineOrderTime
} from '../style';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import { OrdersHeaderAddButton } from '../../../../style';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import OrderFreeContext from '../context/context';
import {
    DefineOrderFreeButton,
    DefineOrderFreeItemsContent,
    DefineOrderFreeItemsContentContainer,
    DefineOrderFreeItemsFooter
} from './style';
import EmptyOrderFreeContent from './EmptyOrderFreeContent';
import OrderFreeItem from './OrderFreeItem';
import { useFormikContext } from 'formik';
import useGetCurrentDriversQuery from '../../../../../../core/api/hooks/query/orders/useGetCurrentDriversQuery';
import FormikSelectField from '../../../../../../components/Formik/FormikSelectField';
import FormikInputCurrency from '../../../../../../components/Formik/FormikInputCurrency';
import FormikDatepicker from '../../../../../../components/Formik/FormikDatepicker';
import FormikTimepicker from '../../../../../../components/Formik/FormikTimepicker';




const OrderFreeItemsContent = () => {

    const { values, setValues, setFieldValue } = useFormikContext() as any;
    const {items, addItem} = useContext(OrderFreeContext); 
    
    const handlerAddItem = useCallback(()=> {
        const id = new Date().getTime() + 1;
        addItem(id);
    },[addItem]);


    const  { data: listState } = useGetCurrentDriversQuery({ active: true });

    const options = useMemo(() => (listState || []).map((x: any)=> ({
        label: `${x?.driver?.firstName} ${x?.driver?.lastName}`,
        value: `${x.driverId}`
    })),[listState]);


    useEffect(() => {
        if(!items) return;
        let obj = {};
        items.forEach((x: any)=> {
            obj = {
                ...obj,
                [`${x.id}_locationName`]: values?.[`${x.id}_locationName`] || x?.[`${x.id}_locationName`] || x?.['locationName'],
                [`${x.id}_description`]: values?.[`${x.id}_description`] || x?.[`${x.id}_description`] || x?.['description'],
                [`${x.id}_address`]: values?.[`${x.id}_address`] || x?.[`${x.id}_address`] || x?.['address'],
                [`${x.id}_addressObject`]: values?.[`${x.id}_addressObject`] || x?.[`${x.id}_addressObject`] || x?.['_addressObject'],
                [`${x.id}_pickUpValue`]: values?.[`${x.id}_pickUpValue`] || x?.[`${x.id}_pickUpValue`] || x?.['_pickUpValue'],
                [`${x.id}_amount`]: values?.[`${x.id}_amount`] || x?.[`${x.id}_amount`] || x?.['amount']
            };
        });
        setValues({
            ...(values || {}),
            ...obj
        });
    }, [items]);

    const changeIsScheduled = useCallback((value: boolean)=> {
        if(!value) {
            setFieldValue('orderTime', '', false);
            setFieldValue('driverId', values.tempDriverId, false);
            setFieldValue('tempDriverId', undefined as any, false);
        } else {
            setFieldValue('tempDriverId', values.driverId, false);
            setFieldValue('driverId', undefined as any, false);
        }
    }, [setFieldValue, values]);

    return (
        <DefineOrderForm>
            <DefineOrderRow justify={'space-between'}>
                <Translation use={Translate.defineOrderSectionItemsTitle} as={DefineOrderSectionLabel} />
                <OrdersHeaderAddButton
                    onClick={handlerAddItem}
                    startIcon={<AddIcon />}
                >
                    <Translation use={Translate.add} />
                </OrdersHeaderAddButton>
            </DefineOrderRow>
            <DefineOrderFreeItemsContentContainer>
                {items.length ? (<DefineOrderFreeItemsContent>
                    {items.map((item: any, index: number) => <OrderFreeItem item={item} key={index} />)}
                </DefineOrderFreeItemsContent>) : <EmptyOrderFreeContent />}
            </DefineOrderFreeItemsContentContainer>
            <DefineOrderRow>
                <FormikSelectField
                    name={'driverId'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterDriver}
                    options={options}
                    disabled={!!values?.isScheduled}
                />

                <FormikInputCurrency
                    name={'orderDeliveryAmount'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterDeliveryAmount}
                />

                <FormikDatepicker
                    name={'orderDate'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterOrderDate}
                    disabled={!values?.isScheduled}
                />
                <DefineOrderTime>
                    <DefineOrderIsScheduled
                        name={'isScheduled'}
                        label={Translate.defineOrderFooterIsScheduled}
                        type={'checkbox'}
                        labelPlacement={'start'}
                        handlerAfterChange={changeIsScheduled}
                    />
                    <FormikTimepicker
                        name={'orderTime'}
                        variant={'outlined'}
                        label={Translate.defineOrderFooterOrderTime}
                        disabled={!values?.isScheduled}
                    />
                </DefineOrderTime>
            </DefineOrderRow>
            <DefineOrderFreeItemsFooter>
                <DefineOrderFreeButton type="submit">
                    <Translation use={Translate.create} />
                </DefineOrderFreeButton>
            </DefineOrderFreeItemsFooter>
        </DefineOrderForm>
    );
};


export default OrderFreeItemsContent;