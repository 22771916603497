import { AxiosResponse } from 'axios';
import { CONTEXT_TYPES } from '../../../../../context/d';
import store from '../../../../../store';
import { clearUserData, setAuthData } from '../../../../../store/app/appSlice';
import axiosClient from '../index';

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem(CONTEXT_TYPES.REFRESH_TOKEN);
    const result = await  axiosClient.post('/refresh-token',{
        token: refreshToken
    });
    return result.data;
};


export function authRequest(config: any) {
    const token = store.getState().app.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Access-Control-Expose-Headers'] = 'version-app';
    return config;
}

export const authResponse = [
    function (response: AxiosResponse) {
        const version = response.headers['version-app'];
        return {
            ...response,
            version
        } as any;
    },
    async function (error: any) {
        const originalRequest = error.config;
        if(error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const data = await refreshAccessToken();
                store.dispatch(setAuthData(data));
                localStorage.setItem(CONTEXT_TYPES.ACCESS_TOKEN, data.token);
                originalRequest.headers['Authorization'] = `Bearer ${data.token}`;
                return axiosClient(originalRequest);
        }


        if (error?.response?.status >= 500 || error.code === 'ERR_NETWORK') {
            /** logout */
            store.dispatch(clearUserData());
            localStorage.removeItem(CONTEXT_TYPES.ACCESS_TOKEN);
            localStorage.removeItem(CONTEXT_TYPES.REFRESH_TOKEN);
            return;
        }
        return Promise.reject(error);
    },
];
