import axiosClient from 'core/api/config/axios';
import { useQuery } from '@tanstack/react-query';
import { createUrl } from 'core/api/utils/createUrl';


const useAuthUserQuery = () => {
    const { data } = useQuery({
        queryKey: ['auth-user'],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({ url: '/account/auth-user', version: 'v1' }));
            return { ...res.data?.data, version: res.version } || { version: res.version };
        },
        retry: false,
        refetchOnWindowFocus: false
    });
    return data;
};

export default useAuthUserQuery;
